import React, { useCallback, useMemo, useContext, useState } from 'react'
import { Button } from '@refera/ui-web'

import useCanDoButtonAction from '_hooks/use-can-do-button-action'
import useRolePermission from '_hooks/use-role-permission'
import { BUTTONS_ACTION, STEP_STATUS } from '_utils/constants/service-order'
import { BudgetContext } from '_utils/budget'
import useToggle from '_/hooks/use-toggle'
import ImportBudgetTemplateForm from '_components/budget/navbar/import-budget-model-button/import-budget-template-form'
import DialogModal, { WARNING_MODAL } from '_components/modal/modal-dialog'
import { useParams } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import { getBudgetTemplates } from '_modules/budgetTemplate/actions'
import { budgetTemplateSelector } from '_modules/budgetTemplate/selector'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import { getProvidersOrders } from '_modules/provider/actions'
import { userSelector } from '_modules/authentication/selectors'
import { useDialog } from '_/hooks/use-dialog'

const ImportBudgetModelButton = () => {
  const { isAdmin, isTradesman } = useRolePermission()
  const [isImportModelModalOpen, toggleImportModelModalOpen] = useToggle()
  const [canImportBudgetTemplate, toggleCanImportBudgetTemplate] = useToggle()
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const [modalDialog, setModalDialog] = useState({ isOpen: false, subTitle: '' })
  const budgetTemplatesSelector = useSelector(budgetTemplateSelector)
  const user = useSelector(userSelector)
  const [serviceOrdersId, setServiceOrdersId] = useState()
  const { showDialog, closeDialog } = useDialog()

  const dispatch = useDispatch()

  const { isEditing, isLoading } = useContext(BudgetContext)
  const { budgetId } = useParams()

  const canImportBudgetTemplateModel = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.IMPORT_BUDGET_TEMPLATE,
    isBudgetRelated: true,
  })

  const isTemporaryButtonVisible = useMemo(
    () => ((canImportBudgetTemplateModel && isTradesman) || isAdmin) && isEditing,
    [canImportBudgetTemplateModel, isAdmin, isTradesman, isEditing]
  )

  const handleImportReject = useCallback(() => {
    setModalDialog({
      isOpen: true,
      subTitle: 'Este chamado encontra-se em uma Etapa que não permite mais esta ação.',
      type: WARNING_MODAL,
    })
    toggleCanImportBudgetTemplate()
  }, [])

  const handleSave = useCallback(() => {
    if (canImportBudgetTemplateModel && serviceOrder?.stepStatus === STEP_STATUS.WAITING_BUDGET) {
      toggleImportModelModalOpen()
    } else {
      handleImportReject()
    }
  }, [])

  if (!isTemporaryButtonVisible) {
    return null
  }

  const handleOnShowConfirmation = async () => {
    dispatch(getBudgetTemplates())
    await dispatch(getProvidersOrders({ provider_id: user.getServiceProviderId })).then(res => {
      setServiceOrdersId(res)
    })
    showDialog({
      type: 'warning',
      subject: 'Você tem certeza que deseja executar esta ação?',
      labelApprove: 'Sim',
      labelCancel: 'Não',
      onApprove: () => {
        handleSave()
        closeDialog()
      },
      onCancel: closeDialog,
    })
  }
  return (
    <>
      {isTradesman && (
        <Button
          onClick={handleOnShowConfirmation}
          color="primary"
          variant="secondary"
          disabled={isLoading}
        >
          Importar orçamento
        </Button>
      )}
      {isImportModelModalOpen && budgetTemplatesSelector && (
        <ImportBudgetTemplateForm
          closeModal={toggleImportModelModalOpen}
          budget={budgetId}
          budgetTemplates={budgetTemplatesSelector}
          serviceOrdersId={serviceOrdersId}
        />
      )}
      {canImportBudgetTemplate && (
        <DialogModal modalDialog={modalDialog} setModalDialog={setModalDialog} />
      )}
    </>
  )
}

export default ImportBudgetModelButton
